.file-uploader {
  border: 1px dashed var(--main-color);
  border-radius: 5px;
  background:var(--main-light--color);
  max-width: 365px;
  transition: all 1s ease-in;
  span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  form {
    display: block;
    padding: 32px 20px;
    max-width: 365px;
    position: relative;
    padding-bottom: 77px;
  }
  &__item,
  &__area {
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
      position: unset !important;
      cursor: pointer;
    }
  }
  &__item {
    label {
      > div:not(.file-uploader__area) {
        display: none;
        & + div {
          opacity: 0.5;
        }
      }
    }
  }
  span.file-uploader__title {
    padding: 0 30px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;

    @media (max-width: 800px) {
      font-size: 16px;
    }
    color: var(--main-color);
  }
  &__icon {
    display: block;
    width: 77px;
    height: 77px;
    margin-bottom: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    fill: var(--main-color);
  }
  &__area {
    > span {
      margin-bottom: 15px;
    }
  }
  &__types {
    display: block;
    margin-top: -15px;
    padding: 0 20px 32px 20px;
  }
  &__bottom {
    position: absolute;
    padding: 0px 20px 32px 20px;
    bottom: 0;
  }
  .files__container {
    width: 100%;
    overflow: hidden;
  }
  .file {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 20%;
    span {
      display: block;
      max-width: 60%;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--main-color);
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      cursor: pointer;
      text-decoration: underline;
      &:hover{
        color:var(--main-color-hover)
      }
    }

    &__delete {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 20px;
      cursor: pointer;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('/assets/img/icon/delete.svg');
    }
  }
  .file-uploader__files {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 15px;
      min-width: 150px;
      margin-left:0;
      span {
        margin: 0 auto;
      }
    }
  }
  span.file-uploader__button {
    background-color: var(--main-light--color);
    border: 1px solid var(--main-color);
    padding: 8px 16px;
    border-radius: 5px;
    margin-bottom: 0;
    position: relative;
    box-shadow: none;
    outline: none;
    color: var(--main-color);
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    svg{
      width: 20px;
      height: 20px;
      stroke:var(--main-color);
      fill: transparent;
      margin-left: 5px;
    }
    &:hover {
      color:var(--main-color-hover);
      svg{
        stroke: var(--main-color-hover);
      }
    }
  }
  .send-button {
    margin-bottom: 15px;
  }
}

.progress-bar {
  width: 100%;
  position: relative;
  &__status {
    // position: relative;
    // transition: all 0.4s ease-in;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 22px;
    color: var(--main-color);
    position: absolute;
    display: block;
    text-align: right;
    // left: 0;
    right: 0;
    top: 0;
  }

  > div {
    height: 8px;
    margin-top: 25px;
    margin-bottom: 25px;

    > div {
      background: var(--main-color);
      border-radius: 5px;
    }
  }
  .progress {
    height: 8px;
  }
}
