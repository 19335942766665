.rdrStartEdge.rdrEndEdge {
  background: var(--main-color);
}
.rdrStartEdge {
  background: var(--main-color);
}
.rdrEndEdge,
.rdrInRange {
  background: var(--main-color);
}
.rdrDateDisplayItem {
  border-color: var(--main-color) !important;
}
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  border-color: var(--main-color);
}
.rdrDayToday .rdrDayNumber span:after {
  background: var(--main-color);
}
.rdrMonthPicker {
  line-height: normal;
}
