.tooltip {
  display: block;
  &__icon {
    width: 18px;
    height: 18px;
    fill: var(--disabled-text-color);
  }
  &:hover {
    cursor: pointer;
  }
}
