.date-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span:last-of-type {
    color: #595959;
    font-size: var(--font-size-12);
  }
}
//
//.client__name {
//  span:first-child {
//    font-weight: 700;
//    &:hover {
//      opacity: 0.7;
//    }
//  }
//  a {
//    text-decoration: none;
//    color: inherit;
//    display: flex;
//    flex-direction: column;
//    font-size: var(--font-size-12);
//    font-family: 'Inter', sans-serif;
//  }
//}
//
//.client__id:hover {
//  cursor: pointer;
//  opacity: 0.7;
//}
//
//.client__warning {
//  display: flex;
//}
//
//.client__status {
//  display: flex;
//  flex-direction: column;
//
//  span {
//    font-size: var(--font-size-12);
//    font-family: 'Inter', sans-serif;
//    &:first-child {
//      font-weight: 700;
//      text-transform: uppercase;
//    }
//  }
//}
//
//.client__country {
//  display: flex;
//  align-items: center;
//  &-text {
//    display: flex;
//    flex-direction: column;
//  }
//}
//
//.client__balance {
//  span {
//    font-weight: 700;
//  }
//}
//
//.client__deposit {
//  > div {
//    display: flex;
//    flex-direction: column;
//  }
//
//  span:first-child {
//    font-weight: 700;
//  }
//}
//
//.client__affiler,
//.client__lastnote {
//  span {
//    font-weight: 700;
//  }
//  &-empty {
//    text-transform: uppercase;
//
//    opacity: 0.5;
//    font-weight: 400 !important;
//  }
//}
//.empty {
//  opacity: 0.5;
//  font-weight: 400;
//  text-transform: uppercase;
//}
//
//.client__lastnote {
//  display: flex;
//  flex-direction: column;
//  span {
//    font-weight: 400;
//  }
//  span:nth-child(1),
//  span:nth-child(3) {
//    font-weight: 700;
//  }
//}
//
//.client__registration {
//  display: flex;
//  flex-direction: column;
//
//  span:first-child {
//    font-weight: 700;
//  }
//}
//.client__retention {
//  border-radius: 4px;
//
//  &-wrap {
//    display: flex;
//    flex-direction: column;
//    border-radius: 4px;
//    border: 1px solid transparent;
//    padding: 3px;
//
//    &.new {
//      border: 1px solid var(--purple);
//    }
//  }
//}
//
//.client__sales {
//  &-wrap {
//    display: flex;
//    flex-direction: column;
//    border-radius: 4px;
//    border: 1px solid var(--purple);
//    padding: 3px;
//  }
//  .sales-new {
//    border-color: var(--purple) !important;
//  }
//
//  &-status {
//    color: var(--purple);
//
//    text-transform: uppercase;
//    font-size: var(--font-size-14);
//    font-weight: 600;
//  }
//}
//
//.trading-table {
//  overflow-x: auto;
//  &_td {
//    overflow: hidden;
//    span {
//      display: block;
//      //font-weight: 700;
//
//      &:nth-of-type(2) {
//        font-weight: 400;
//        color: var(--secondary-text-color);
//      }
//    }
//  }
//  .ta {
//    position: relative;
//    display: inline-block;
//  }
//  .live {
//    font-size: 9px;
//    background-color: var(--green);
//    display: inline-block;
//    padding: 2px 4px;
//    border-radius: 4px;
//    color: var(--white);
//    line-height: normal;
//    position: absolute;
//    top: -4px;
//    right: -34px;
//    &:first-letter {
//      text-transform: uppercase;
//    }
//  }
//  .demo {
//    font-size: 9px;
//    background-color: var(--red);
//    display: inline-block;
//    padding: 2px 4px;
//    border-radius: 4px;
//    color: var(--white);
//    line-height: normal;
//    position: absolute;
//    top: -4px;
//    right: -34px;
//    &:first-letter {
//      text-transform: uppercase;
//    }
//  }
//  .deposit,
//  .completed {
//    color: var(--green);
//    text-transform: uppercase;
//  }
//  .pending {
//    color: var(--blue);
//    text-transform: uppercase;
//  }
//  .canceled {
//    text-transform: uppercase;
//    color: var(--red);
//  }
//
//  table {
//    min-width: 1500px !important;
//
//    th,
//    span {
//      //font-size: var(--font-size-11);
//    }
//    th:nth-child(1) {
//      width: 2% !important;
//    }
//    th:nth-child(2) {
//      width: 1% !important;
//    }
//    th:nth-child(3) {
//      width: 2% !important;
//    }
//    th:nth-child(4) {
//      width: 2% !important;
//    }
//    th:nth-child(5) {
//      width: 2% !important;
//    }
//    th:nth-child(6) {
//      width: 2% !important;
//    }
//    th:nth-child(7) {
//      width: 2% !important;
//    }
//    th:nth-child(8) {
//      width: 2% !important;
//    }
//    th:nth-child(9) {
//      width: 1.5% !important;
//    }
//    th:nth-child(10) {
//      width: 1.5% !important;
//    }
//    span {
//      font-family: 'Inter', sans-serif;
//    }
//  }
//}
//
//.verified,
//.not_verifed,
//.blocked {
//  text-transform: uppercase;
//}
//.verified {
//  color: var(--green);
//}
//.blocked {
//  color: var(--red);
//}
//.not_verified {
//  color: var(--yellow);
//}
//
//.status.active,
//.status.new {
//  color: var(--purple);
//  text-transform: uppercase;
//  font-size: var(--font-size-14);
//  font-weight: 600;
//}
//
//.converted {
//  color: var(--green);
//  text-transform: uppercase;
//  font-weight: 600;
//}
//
//.warning {
//  color: var(--red);
//}
//
//.icon {
//  display: block;
//  width: 24px;
//  height: 15px;
//  margin-right: 10px;
//  background-repeat: no-repeat;
//  background-position: center center;
//  border: 1px solid var(--light-grey);
//}
//.sell {
//  color: var(--red);
//  font-weight: 700;
//}
//.buy {
//  color: var(--green);
//  font-weight: 700;
//}
