.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__logo {
    margin-bottom: 100px;
  }

  &__img {
    display: block;
    margin-bottom: 35px;
    width: 289px;
    height: 191px;
    background: url('../../assets/img/404.svg') no-repeat;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 620px;
  }

  span {
    display: block;

    font-family: 'Inter', sans-serif;
    font-style: normal;
  }
  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 29px;
    color: #262626;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }

  &__link {
    margin-top: 30px;
  }

  .link-text {
    color: var(--purple);
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      color: var(--purple-hover);
      cursor: pointer;
    }
  }
}
