.chat-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--main-color);
  font-size: 10px;
  color: #fff;
}
