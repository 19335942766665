.logo__semi_big {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;

  svg {
    width: 135px;
    height: 54px;
  }
}

.sidebar {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 14px;
  font-weight: 500;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-left: 50px;
    transition: all 0.3s ease-in-out;

    svg, img {
      max-width: 90%;
      max-height: 45px;
    }
  }

  .btn {
    padding: 13px 16px;
    border-radius: 5px;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    outline: none;
    border: none;
    color: var(--white);
    //margin-right: 4px;
    font-size: 16px;
    min-width: 190px;
    margin: 0.55rem 1.5rem 0.55rem 4rem;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      background-color: var(--main-color-hover);
    }
  }

  &__bottom {
    padding-bottom: 20px;
  }
}

.btn-menu {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0.55rem 0.6rem 0.55rem 1.5rem;
  background-color: transparent;
  font-weight: 500;
  color: var(--secondary-text-color);
  width: 150px;
  text-align: left;
  text-decoration: none;
  border: none;
  border-right: 2px solid transparent;

  &:hover, &.active {
    border-color: var(--main-color);
    background: #f3f4f6;
    width: 100%;
    color: var(--secondary-text-color);
  }

  &.active > span {
    color: var(--main-color);
  }

  .chat-icon {
    margin-right: 22px;
  }

  svg {
    margin-right: 22px;
  }
}

.sidebar-burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  background: #f6f6f6;
  box-shadow: 1px 0px 0px var(--outline-text-color);

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-left: 50px;
    transition: all 0.3s ease-in-out;

    svg, img {
      max-width: 90%;
      max-height: 45px;
    }
  }

  &__bottom {
    padding-bottom: 20px;
  }

  .logo {
    margin-left: 10px;

    svg {
      width: 100px;
      height: 54px;
    }
  }
}
