.popup {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.34);
  background: #ffffff;
  border-radius: 5px;
  margin: 0;
  overflow: hidden;
  width: 740px;

  @media (max-width: 600px) {
    max-height: 100vh;
    margin: 0 auto;
  }

  @media (max-width: 700px) {
    width: 98%;
  }
  &__close-btn {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/assets/img/icon/close.svg/');
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 24px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    background: var(--main-color);

    @media (max-width: 700px) {
      font-size: 18px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      padding: 24px;
      position: relative;
    }

    &-left {
      width: 33%;
      padding: 43px 15px 24px;
      background: #f6f6f6;

      @media (max-width: 700px) {
        display: none;
      }
    }
    &-right {
      width: 66%;

      @media (max-width: 700px) {
        width: 100%;
      }

      &.full {
        width: 100%;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
    }
  }
}
