.calendar-picker {
  .date-range {
    position: relative;
    margin-bottom: 15px;
    .date-input {
      position: relative;
      label,
      input {
        line-height: 20px;
      }
      input {
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 10px 12px;
        border: 1px solid #c2c2c2;
        border-radius: 4px;
        outline: none;
        background-color: var(--white);
        &.error {
          border-color: var(--red) !important;
        }
        &:focus {
          border-color: var(--main-color);
        }
        &:active {
          border-color: var(--primary-text-color);
        }
        &:disabled {
          background: var(--light-grey-disabled);
          border-color: #c2c2c2;
        }
      }

      .icon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: var(--secondary-text-color);
      }
    }

    .date-range {
      position: absolute;
      top: 64px;
      z-index: 10;
      left: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: #fff;
    }

    .label {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 5px;
      color: var(--primary-text-color);
      display: block;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    //.rdrDayToday {
    //  background-color: var(--main-color) !important;
    //}
    .rdrDateDisplayItem {
      border-color: var(--main-color) !important;
    }
    .rdrDayToday .rdrDayNumber span:after {
      background: var(--main-color);
    }
    .rdrMonthPicker {
      line-height: normal;
    }
  }
}

