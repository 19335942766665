.amount {
  display: flex;
  flex-direction: column;
  &__input,
  &__buttons,
  &__wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__input {
    @media (max-width: 600px) {
      margin-bottom: 20px;
      margin-right: 0 !important;
    }
  }
  &__wrap {
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  &__buttons {
    justify-content: space-between;
    max-width: 56%;
    @media (max-width: 600px) {
      max-width: 100%;
      justify-content: flex-end;
      width: 100%;
    }
    button {
      margin: 0;
      padding: 10px 10px !important;
    }
    button:not(:hover) {
      opacity: 0.8;
    }
    button:not(:last-of-type) {
      margin-right: 7px;
    }
  }
  &__label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #262626;
  }
  &__wrap {
    justify-content: space-between;
    > div:not(:last-of-type) {
      margin-right: 15px;
    }
  }
  .error {
    input[type='number'] {
      border: 1px solid var(--red) !important;
    }
  }
  &__input {
    position: relative;
    overflow: hidden;
    display: block;
    min-width: 30%;
    .amount__currency {
      display: flex;
      align-items: center;
      position: absolute;
      left: 1px;
      top: 1px;
      bottom: 1px;
      padding: 9px;
      background: #f5f5f5;
      border-radius: 3px 0px 0px 3px;
      border-right: 1px solid #00000020;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #595959;
    }
    input[type='number'] {
      padding: 12px 10px 13px 66px;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      color: #595959;
      font-weight: 400;
      font-size: 14px;
      border-radius: 4px;
      transition: 0.5s;
      display: block;
      width: 100%;

      &:active,
      &:hover,
      &:focus {
        &:not(:disabled) {
          outline: none;
          border: 1px solid #262626 !important;
        }
      }
    }
    .counters {
      position: absolute;
      right: 3px;
      top: 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 95%;
      background: #fff;
      i {
        display: block;
        width: 8px;
        height: 5px;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('/assets/img/icon/ic_caret_down.svg/');
      }
      .increace {
        transform: rotate(180deg);
        margin-bottom: 5px;
      }
    }
  }
  p.errorName {
    margin-top: 4px;
    font-size: 13px;
    line-height: 14px;
    color: var(--red);
    width: 100%;
    text-align: left;
    z-index: 1;
    background: var(--light-red);
    border-radius: 4px;
    overflow: hidden;
    padding: 8px 12px;
  }
  p.details {
    margin-top: 4px;
    font-size: 13px;
    line-height: 14px;
    width: 100%;
    text-align: left;
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    padding: 8px 0;
  }

  .disabled {
    pointer-events: none;
  }
}
