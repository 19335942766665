.popup {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.34);
  background: #ffffff;
  border-radius: 5px;
  margin: 0;
  overflow: hidden;
  max-width: 710px;
  @media (max-width: 700px) {
    margin: 10px;
  }

  &__close-btn {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/assets/img/icon/close.svg/');
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 24px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    background: var(--main-color);
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      padding: 24px;
      position: relative;
    }

    &-left {
      width: 33%;
      padding: 43px 15px 24px;
      background: #f6f6f6;

      @media (max-width: 700px) {
        display: none;
      }
    }
    &-right {
      width: 66%;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
    .icon {
      display: block;
      margin: 0 auto 20px;
      width: 96px;
      height: 96px;
      opacity: 0.7;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('/assets/img/pics.png/');
    }
    &-info {
      display: flex;
      flex-direction: column;
    }
    .info__title {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #262626;
      margin-bottom: 11px;
    }
    .info__description {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #595959;
    }
    &-controls {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .controls__title {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #262626;
      margin-bottom: 16px;
      display: block;
    }
    .controls__amount {
      margin-bottom: 15px;
      margin-top: 15px;

      @media (max-width: 700px) {
        margin-bottom: 15px;
        width: 100%;
      }
    }
    .controls__warning {
      display: block;
      padding: 8px 10px;
      margin: 25px 0 25px;
      background: rgba(242, 153, 74, 0.12);
      border-radius: 4px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #595959;
    }
    .controls__buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: auto 0 0;
      gap: 6px;
      .hidden {
        visibility: hidden;
      }
      button {
        margin: 0;
      }
    }
  }
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.34);
}

.popup__content-steps {
  overflow: hidden;
  display: flex;
  width: 200%;
}

.popup__content-step {
  width: 100%;
  min-height: 305px;
  transform: translate(0px);
  opacity: 1;
  transition: all 0.3s ease-in;

  display: flex;
  flex-direction: column;
}

.popup__content-step.step-1 {
  left: 0;
}

.popup__content-step.step-1.hide {
  transform: translate(-100%);
  opacity: 0;
}

.popup__content-step.step-2 {
  opacity: 1;
  transform: translate(-100%);
}

.popup__content-step.step-2.hide {
  opacity: 0;
  transform: translate(0%);
}

.controls__amount {
  display: flex;
  align-items: center;
  span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
  }
}

.controls__amount-wrapp {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 40%;
  }
}

.popup__content-right.withdraw-content {
  .controls__amount {
    margin-right: 15px;

    // span {
    //   margin-right: 30px;
    // }
  }

  @media (max-width: 600px) {
    width: 320px !important;

    .controls__amount-wrapp {
      flex-direction: column;

      .controls__amount {
        margin: 0;
        flex-direction: column;

        span {
          margin-right: 0;
        }
        svg {
          transform: rotate(90deg);
        }
      }
      > div {
        width: 100%;
      }
    }

    .controls__select {
      margin-bottom: 20px;
    }
    .crypto-type__menu-list {
      max-height: 150px !important;
    }
  }
}
