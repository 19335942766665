.loader__button {
  .btn {
    //padding: 8px 16px;
    border-radius: 36px;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    outline: none;
    border-width: 1px;
    border-color: var(--main-color);
    margin-left: 15px;
    line-height: 18px;
    position: relative;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &__text {
      color: var(--white);
      margin-right: 4px;
      font-weight: 500;
      font-size: 14px;
    }
    &:hover {
      cursor: pointer;
      background-color: var(--main-color-hover);
      border-color: var(--main-color-hover);
    }

    &.big {
      .btn__text {
        font-size: 16px;
      }
      .btn__icon {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    &.secondary {
      background-color: var(--purple-100);
      .btn__text {
        color: var(--main-color);
      }
      .btn__icon {
        svg {
          width: 16px;
          height: 16px;
          fill: var(--main-color);
        }
      }
      &:hover {
        .btn__text {
          color: var(--main-color-hover);
        }
        .btn__icon {
          svg {
            fill: var(--main-color-hover);
          }
        }
      }
    }
    &.outline {
      background-color: var(--purple-100);
      border: 1px solid var(--main-color);
      .btn__text {
        color: var(--main-color);
      }
      .btn__icon {
        svg {
          width: 16px;
          height: 16px;
          fill: var(--main-color);
        }
      }
      &:hover {
        border-color: var(--main-color-hover);
        .btn__text {
          color: var(--main-color-hover);
        }
        .btn__icon {
          svg {
            fill: var(--main-color-hover);
          }
        }
      }
    }
    &.text {
      background-color: transparent;
      .btn__text {
        color: var(--main-color);
      }
      .btn__icon {
        svg {
          width: 16px;
          height: 16px;
          fill: var(--main-color);
        }
      }
      &:hover {
        border-color: var(--main-color-hover);
        .btn__text {
          color: var(--main-color-hover);
        }
        .btn__icon {
          svg {
            fill: var(--main-color-hover);
          }
        }
      }
    }
    &:disabled {
      background-color: var(--light-grey-disabled);
      color: var(--disabled-text-color);
      border: 1px solid var(--disabled-text-color);
      &:hover {
        border-color: var(--disabled-text-color);
        cursor: not-allowed !important;
        .btn__text {
          color: var(--disabled-text-color) !important;
        }
        .btn__icon {
          svg {
            fill: var(--disabled-text-color) !important;
          }
        }
      }

      .btn__text {
        color: var(--disabled-text-color) !important;
      }
      .btn__icon {
        svg {
          fill: var(--disabled-text-color) !important;
        }
      }
    }
    &.container-btn {
      min-width: 150px;
      padding: 10px 20px;
      border-radius: 36px;

      span {
        margin: 0 auto;
      }

      .btn__icon {
        display: none;
      }
    }

    .hidden {
      visibility: hidden;
    }
  }
}
