.chat-input {
  z-index: 1;
  position: relative;
  width: 100%;

  textarea {
    width: 100%;
    min-height: 42px;
    max-height: 200px;
    overflow-y: auto;
    resize: none;
    padding: 8px 40px 8px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 4px;
    border: 1px solid var(--main-color);
    background: var(--white);
  }
}