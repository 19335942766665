$header-height: 70px;

.chat {
  position: relative;
  display: flex;
  height: 100vh;
  padding-top: $header-height;
  margin-top: -$header-height;

  .chat-content {
    width: 100%;
    padding: 15px;
  }

  .connection-status {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    padding: 3px 15px;
    background-color: var(--red-color-hover);
    font-size: 14px;
    text-align: center;
    color: #fff;
  }

  .chat-empty-title {
    opacity: .3;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--main-color);
  }
}