.side-navigation-panel-select-option {
  border-left: none;
  border-right-width: 2px;
  border-color: transparent;
  color: var(--secondary-text-color) !important;
  padding: 0.55rem 0.6rem 0.55rem 1.5rem !important;
  font-size: 16px;
}
.side-navigation-panel-select-option-selected,
.side-navigation-panel-select-inner-option-selected {
  border-right-width: 2px;
  border-left: none;
  color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  transition: width 0.5s ease-in-out;
}
.side-navigation-panel-select-option:hover {
  border-color: var(--main-color) !important;
}
.side-navigation-panel-select-option-text {
  width: 150px;
  margin-left: 22px !important;
}
.side-navigation-panel-select-inner-option:hover {
  border-right-width: 2px;
  border-left: none;
  border-color: var(--main-color) !important;
}
.side-navigation-panel,
.side-navigation-panel-select,
.side-navigation-panel-select-inner,
.side-navigation-panel-select-inner-wrap,
.side-navigation-panel-select-inner-option {
  border-left: none;
}
.side-navigation-panel {
  padding: 20px 0 20px;
}
.side-navigation-panel-select-option {
  border-left: none;
  border-right-width: 2px;
  border-color: transparent;
  color: var(--secondary-text-color) !important;
  padding: 0.55rem 0.6rem 0.55rem 1.5rem !important;
}

.side-navigation-panel-select-option:hover {
  border-color: var(--main-color) !important;
}

.side-navigation-panel-select-option-selected,
.side-navigation-panel-select-inner-option-selected {
  border-right-width: 2px;
  border-left: none;
  color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.side-navigation-panel-select-option-text {
  width: 150px;
  margin-left: 22px !important;
}

.side-navigation-panel-select-inner-option-text {
  width: 150px;
}

@media screen and (max-width: 899.98px) {
  .side-navigation-panel-select-inner-option {
    position: relative;
    left: -73px;
  }

  .side-navigation-panel-select-inner-option-wrap {
    position: relative;
    right: -73px;
  }
}

.side-navigation-panel-select-option-wrap img {
  max-width: 25px;
}

.side-navigation-panel {
  padding: 20px 0 20px;
}

.side-navigation-panel-select-inner-option:hover {
  border-right-width: 2px !important;
  border-color: var(--main-color) !important;
}

@media screen and (min-width: 899.98px) {
  .side-navigation-panel:hover .side-navigation-panel-select-inner-option {
    left: 0;
  }

  .side-navigation-panel:hover .side-navigation-panel-select-inner-option-wrap {
    right: 0;
  }
}

.side-navigation-panel
.side-navigation-panel-select
.side-navigation-panel-select-inner
.side-navigation-panel-select-inner-wrap
.side-navigation-panel-select-inner-option {
  padding: 0.5rem 0.55rem 0.5rem 4.4rem;
  display: inline-flex;
  position: relative;
  border-left: none;
  left: -12.5rem;
  transition: left 0.3s ease-in-out;
}

@media screen and (max-width: 899.98px) {
  .side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option {
    padding: 0.5rem 6.99rem 0.5rem 4.4rem;
    display: inline-flex;
    left: -4.58rem;
  }
}

@media screen and (min-width: 899.98px) {
  .side-navigation-panel:hover
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
    left: 0;
  }
}

@media screen and (min-width: 899.98px) {
  .side-navigation-panel-select-inner-option-wrap {
    visibility: hidden;
    transition: visibility 0.3s ease-in-out;
  }

  .side-navigation-panel:hover .side-navigation-panel-select-inner-option-wrap {
    visibility: visible;
  }
}

.side-navigation-panel-select-inner-wrap svg {
  min-width: 15px;
}

.side-navigation-panel-select-option-icon.side-navigation-panel-select-option-icon-chevron-down {
  min-width: 20px;
}




