.wire-transfers-table {
  table {
    thead tr {
      th:nth-child(1) {
        width: 19% !important;
      }
      th:nth-child(2) {
        width: 15% !important;
      }
      th:nth-child(3) {
        width: 15% !important;
      }
      th:nth-child(4) {
        width: 10% !important;
      }
      th:nth-child(5) {
        width: 10% !important;
      }
      th:nth-child(6) {
        width: 10% !important;
      }
    }
  }

  .trading_account {
    position: relative;
    span {
      position: relative;
      top: -10px;
      text-transform: uppercase;
      padding: 2px 8px;
      border-radius: 50px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      background: #fbad15;
      margin-left: 5px;
      color: #ffffff;
    }
  }

  .account-name {
    display: flex;
    flex-direction: row;
    span:first-child {
      word-break: break-all;
      max-width: 50%;
    }
    span {
      display: block;
    }
    .active,
    .blocked {
      display: block;
      color: #ffffff;
      padding: 2px 8px;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      margin-left: 10px;
    }
    .active {
      background: #53c41a;
      align-self: center;
    }
    .blocked {
      background: #f6222e;
    }
  }

  .wire-status {
    & span:nth-child(2) {
      color: #595959;
      font-size: 12px;
    }

    & .wire-status__item {
      text-transform: uppercase;
    }

    & .process, .processing {
      color: #2f80ed;
    }

    & .success {
      color: var(--green);
    }

    & .declined {
      color: var(--yellow);
    }

    & .error, .failed {
      color: var(--red);
    }
  }
}
