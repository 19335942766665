.payments {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & .payments__header {
    font-size: 20px;
    margin-bottom: 30px;
  }
  & .payments__body {
    margin-bottom: 30px;
    & span {
      font-weight: bold;
    }
  }
  & .text-link {
    color: var(--main-color);
    font-size: 20px;
    text-decoration: underline;
    &:hover {
      color: var(--main-color-hover);
      cursor: pointer;
    }
  }
}

