.table-container {
  overflow-x: scroll;
  //@media (max-width: 1200px) {
  //  overflow-x: scroll;
  //  table {
  //    width: 900px !important;
  //  }
  //}
  table {
    border-spacing: 0;
    border-collapse: unset;
    table-layout: fixed;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    width: 100%;
    //min-width: 1500px;
    position: relative;
    overflow: hidden;
    @media (max-width: 1200px) {
      width: auto;
      min-width: 1000px;
    }
    tr {

      &:last-child {
        td {
          border-bottom: 0;
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    td {
      vertical-align: top !important;
    }

    th,
    td {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin: 0;
      padding: 15px 8px;
      border-bottom: 1px solid #ebebeb;
      vertical-align: middle;
      &:last-child {
        border-right: 0;
      }
    }

    th {
      border-right: 1px solid #ebebeb;
      font-size: 12px;
      text-transform: uppercase;
      color: var(--main-color);
      text-align: left;
      position: relative;
      &:last-child {
        border-right: 0;
      }
    }

    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 0;
      opacity: 0;
      z-index: 99;
    }

    thead {
      tr {
        background: #fafafa;
      }
      // th:nth-child(1) {
      //   width: 5%;
      // }
      // th:nth-child(2) {
      //   width: 19%;
      // }

      //th:nth-child(1) {
      //  width: 20%;
      //}
      //th:nth-child(2) {
      //  width: 10%;
      //}
      //th:nth-child(3) {
      //  width: 15%;
      //}
      //th:nth-child(4) {
      //  width: 10%;
      //}
      //th:nth-child(5) {
      //  width: 15%;
      //}
      //th:nth-child(6) {
      //  width: 20%;
      //}
    }

    tbody {
      &.empty {
        display: block;
        min-height: 100px;
      }
      td {
        color: #262626;
        font-family: 'Inter', sans-serif;
        font-size: 14px;

        @media (min-width: 800px) and (max-width: 1200px) {
          font-size: 12px;
        }
      }

      tr:hover {
        td {
          background: rgb(249, 241, 255) !important;
        }
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .deposit,
  .completed {
    color: #53c41a;
  }

  .withdraw {
    color: #f6222e;
  }

  .pending {
    color: #2f80ed;
  }

  .empty-msg {
    position: absolute;
    top: 60px;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    & td {
      font-size: 26px;
    }
  }
}

.preloader {
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 0;
  background: #ffffff30;

  &__inner {
    width: 100%;
    height: 100%;
  }
}
