.chat-system-message {
  padding: 10px 15px;

  &:last-child {
    margin-top: 15px;
  }

  .system-message-body {
    font-size: 14px;
    text-align: center;
  }
  .system-message-owner {
    font-weight: 500;
  }
}

.chat-message {
  position: relative;
  display: flex;
  padding: 16px 15px;

  &.active {
    background-color: #fff;
  }

  &.isReply {
    margin-bottom: 10px;
    padding: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      border-radius: 5px;
      background-color: var(--main-color);
    }
  }

  .chat-message-content {
    padding-left: 12px;
  }

  .chat-message-meta {
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .chat-message-name {
    font-weight: 500;
  }

  .user-type {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    &.clients {
      color: var(--chat-status-color);
    }
    &.managers {
      color: var(--main-color);
    }
  }

  .chat-date {
    margin-left: 8px;
  }

  .chat-message-text {
    font-size: 14px;
    line-height: 1.5;

    & > .edited {
      color: rgba(38, 38, 38, 0.5);
    }

    & > span {
      word-break: break-word;
    }
  }

  .chat-message-image {
    display: flex;
    max-height: 205px;
    margin-top: 10px;
    cursor: pointer;

    &.pending {
      position: relative;
      height: 205px;
      width: 300px;
      border-radius: 5px;
      background-color: #272727;
      cursor: not-allowed;
    }

    & > img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 5px;
    }
  }

  .chat-message-document {
    font-size: 14px;
    color: var(--main-color);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  svg {
    flex-shrink: 0;
  }
}