.document-types {
  span {
    font-family: 'Inter', sans-serif;
  }
  &__label {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color:var(--primary-text-color)
  }
  &__name {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    span {
      display: inline-block;

      line-height: 22px;

      &:last-child {
        margin-left: 10px;
        max-width: 70%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @media (max-width: 500px) {
          max-width: 200px !important;
        }
      }
    }
  }

  &__categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;

    @media (max-width: 800px) {
      flex-direction: column;

      > div {
        width: 100% !important;
        margin-bottom: 10px;
      }
    }
    > div {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
  }
}

.document-types__descr {
  display: flex;
  background-color: var(--light-grey-disabled);

  svg {
    margin: auto;
    display: block;
    width: 150px;
    height: 150px;
    margin: auto;
    fill: var(--main-color);
  }
}
