.page {
  padding: 16px 32px;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 40px 16px 16px;
  }

  @media (min-width: 800px) and (max-width: 900px) {
    padding-top: 50px;
  }
  &__title {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 16px;

    @media (max-width: 800px) {
      font-size: 24px;
    }
  }
}
