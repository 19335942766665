.chat-messages-overflow {
  overflow: auto;
  display: flex;
  height: 100%;
  margin-bottom: 10px;

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .scroll-loader {
    position: relative;
    height: 70px;
    padding: 35px 0;
  }
}
