.password_input {
  p.errorName {
    margin-top: 4px;
    font-size: 13px;
    line-height: 14px;
    color: var(--red);
    width: 100%;
    text-align: left;
    z-index: var(--zIndex-super-main);
    background: var(--light-red);
    border-radius: 4px;
    overflow: hidden;
    padding: 8px 12px;
  }

  .input-wrap {
    font-size: 20px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
  }
  .label-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .label {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--primary-text-color);
    display: block;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .input {
    display: block;
    width: 100%;
    font-size: 14px;
    padding: 12px 35px 12px 12px;
    border: 1px solid var(--border-background-color);
    border-radius: 4px;
    outline: none;
    background-color: var(--body-background-color);
    &.error {
      border-color: var(--red) !important;
    }
    &:focus {
      border-color: var(--main-color);
    }
    &:active {
      border-color: var(--primary-text-color);
    }
  }
  .input-icon {
    position: absolute;
    right: 10px;
    top: 11px;
    color: var(--disabled-text-color);
    &:hover {
      cursor: pointer;
    }
  }
  .required {
    color: var(--red);
    margin-left: 3px;
  }
}
