.deals-filter {
  .filters-config {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    .filters-sets {
      width: 250px;
      margin-left: 20px;
    }
    .filters-toggle {
      margin-left: auto;
      label {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-size: var(--font-size-12);
        font-weight: 500;

        span {
          display: block;
          margin-top: 5px;
        }
      }
    }
  }

  .filters__header {
    display: flex;
    justify-content: flex-end;
  }

  .filters__btns {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 20px;
    }
  }

  .datapicker {
    span {
      line-height: 20px;
    }
  }

}
