.documents {
  & .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &.mobile {
      width: 100%;
      .col {
        width: 100%;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div {
      margin-right: 31px;

      @media (max-width: 800px) {
        margin-right: 0;
      }
    }
  }
  .title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--main-color);
  }
  .footer-text {
    margin-top: 26px;
    span {
      display: block;
      margin-bottom: 18px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #000000;

      &.sub-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #262626;
        margin-bottom: 5px;
      }
    }
  }
  .documents-text {
    flex: 1 1 20%;

    @media (max-width: 800px) {
      max-width: 100%;
      margin-top: 20px;
    }
  }
  .mobile {
    width: 100%;
    > div {
      align-self: center;
    }
  }

  .list{
    list-style: unset;
    font-style: italic;
    padding-left: 40px;
  }

}

