.chat-processed {
  position: absolute;
  bottom: calc(100% - 2px);
  right: 0;
  width: 100%;
  padding: 10px 60px 15px 10px;
  border-radius: 5px 5px 0;
  background-color: #d9d9d9;
  box-shadow: 0px -7px 15px 0px #fff;
  font-size: 14px;
  line-height: 1.3;

  &.isReplying {
    .chat-processed-text {
      padding-left: 40px;
    }
  }

  .chat-processed-title {
    margin-bottom: 10px;
    color: var(--main-color);
    & > span {
      padding-left: 3px;
      font-weight: 500;
      color: #000;
    }
  }

  .chat-processed-text {
    word-break: break-word;
  }

  .chat-processed-close {
    position: absolute;
    right: 8px;
    top: calc(50% - 16px);
    cursor: pointer;
  }
}