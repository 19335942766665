.user {
  display: flex;
  align-items: center;
  &__avatar {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    // &:after {
    //   content: '';
    //   position: absolute;
    //   display: block;
    //   width: 14px;
    //   height: 14px;
    //   background-color: #06d6a0;
    //   border: 2px solid #ffffff;
    //   border-radius: 50%;
    //   bottom: -1px;
    //   right: -4px;
    // }
    // &.online {
    // }
  }
}
