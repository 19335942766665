.sign_in {
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-background-color);
    background-size: cover;
    height: 100vh;
    flex-direction: column;
    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .logo {
    position: absolute;
    height: 54px;
    top: -90px;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
    svg,img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .login {
    background-color: #fff;
    min-width: 700px;
    max-width: 767px;
    min-height: 300px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.73);
    display: flex;
    @media (max-width: 768px) {
      min-width: 100%;
    }
    &__col {
      &:nth-child(2) {
        border-radius: 10px 0 0 10px;
        width: 35%;
        max-width: 250px;
        background-color: var(--light-grey-disabled);
        color: var(--light-grey);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 10px 20px 20px;
      }
      &:nth-child(3) {
        width: 70%;
        background-color: var(--body-background-color);
        padding: 20px 20px 20px 20px;
        @media (max-width: 500px) {
          width: 100% !important;
        }
      }
    }
    &__title {
      text-transform: uppercase;
      text-align: center;
      font-size: 24px;

      @media (max-width: 600px) {
        font-size: 20px;
      }

      @media (max-width: 350px) {
        font-size: 17px;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      flex-wrap: wrap;
    }
    &__footer {
      padding-top: 20px;
      font-size: 14px;
    }
  }

  .form {
    width: 100%;
    position: relative;
    .label {
      margin-bottom: 5px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    &__input-wrap {
      width: 100%;
      position: relative;
      margin-bottom: 15px;
      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #000;
        border-radius: 4px;
        outline: none;
      }
      input.error {
        border-color: var(--red);
      }
    }
    .button-wrap {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 14px;
      line-height: normal;

      @media (max-width: 800px) {
        flex-direction: column;

        button {
          width: 100%;
          margin-top: 15px;
          span {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }
      .text-link {
        margin-right: auto;
      }
    }
  }

  .text-link {
    color: var(--main-color);
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      color: var(--main-color-hover);
      cursor: pointer;
    }
  }

  .auth {
    &__descr {
      @media (max-width: 500px) {
        display: none !important;
      }
    }
    &__descr-img {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      & img {
        max-width: 170px;
      }
    }
    &__descr-text {
      max-width: 100%;
      p {
        font-size: 14px;
        color: var(--secondary-text-color);
        margin-bottom: 5px;
      }
      .link-text {
        color: var(--main-color);
        font-size: 14px;
        text-decoration: underline;
        &:hover {
          color: var(--main-color-hover);
          cursor: pointer;
        }
      }
    }
  }
  .sliderPicker {
    width: 80%;
    padding-top: 60px;
  }
}

