:root {
  /* fonts */
  --font-inter: 'Inter', sans-serif;
  --outline-text-color: #c2c2c2;

  /* Changeable colors*/
  --body-background-color: #fff;
  --border-background-color: #262626;
  --header-background-color: #ffffff;
  --sidebar-background-color: #f6f6f6;
  --main-color: #722ed1;
  --main-light-color: #f9f1ff;
  --main-color-hover: #540cb9;
  --primary-text-color: #262626;
  --secondary-text-color: #595959;
  --disabled-text-color: #8c8c8c;
  --main-background-color: linear-gradient(0deg, var(--main-color) 0%, rgb(255, 255, 255) 76%);

  /* color */
  --black: #000000;
  --white: #ffffff;
  --purple: #722ed1;
  --purple-hover: #540cb9;
  --purple-100: #f9f1ff;
  --purple-pressed: #23075e;
  --red: #f6222e;
  --light-red: #ffe9eb;
  --light-red-border: rgb(241, 196, 196);
  --green: #53c41a;
  --yellow: #fbad15;
  --blue: #1bacbf;
  --light-grey: #c2c2c2;
  --light-grey-disabled: #f5f5f5;
  --light-grey-secondary: #f6f6f6;

  /* z-index */
  --zIndex-popup: 10;
  --zIndex-modal: 20;
  --zIndex-popper: 30;
  --zIndex-first-layer: 1;
  --zIndex-subzero: -1;
  --zIndex-super-main: 999;

  /*chat*/
  --chat-avatar-size-sm: 24px;
  --chat-avatar-size-md: 40px;
  --chat-status-color: #06D6A0;
  --chat-client-color: #138d65;
}
