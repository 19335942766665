.chat-message-form {
  position: relative;
  display: flex;
  align-items: center;
  max-height: 200px;
  font-size: 0;

  & > label:focus-within {
    outline: none;
  }

  &.isRedirecting {
    .message-sent-button {
      pointer-events: none;
    }
    textarea {
      font-size: 0;
    }
  }

  .attach-file-button {
    height: 24px;
    width: 24px;
    margin: 0 8px;
    padding: 0;
    background-color: unset;
    border-radius: 50%;
    border: 0;

    &:hover,&:active {
      box-shadow: 0 0 0 1px var(--main-color);
    }

    & > span {
      margin: 0 auto;
    }
  }

  .message-sent-button {
    z-index: 2;
    position: absolute;
    right: 4px;
    bottom: 4px;
    padding: 8px;
    outline: none;
    border: none;
    border-radius: 50%;
    background-color: var(--main-color);
    &:hover, &:active {
      background-color: var(--main-color-hover);
    }
  }
}