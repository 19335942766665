.menu-option {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: var(--light-grey-secondary)
  }

  .menu-option-icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .menu-option-title {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}