.content__policies {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: var(--primary-text-color);
      margin-bottom: 16px;
      display: block;
    }
  }

  &-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: var(--secondary-text-color);
  }

  &-controls {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .text-wrapper {
    overflow-y: auto;
    max-height: 50vh;
  }

  .controls__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;

    button {
      margin: 0;
    }
    .hidden {
      visibility: hidden;
    }
  }
}


