.info {
  height: 100%;
  display: flex;
  svg {
    display: block;
    opacity: 0.7;
    margin: 30% auto auto;
  }
}

.content-new__account {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #262626;
      margin-bottom: 16px;
      display: block;
    }
  }

  &-form {
    height: 100%;
  }

  &-controls {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: auto;
  }

  .controls__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: auto 0 0;
    .hidden {
      visibility: hidden;
    }
    button:not(:last-of-type) {
      margin-right: 5px;
    }
  }
}
