@media (max-width: 500px) {
  .crypto-type__menu-list {
    max-height: 100px !important;
  }
}

@media (min-width: 500px) and (max-width: 1400px) {
  .crypto-type__menu-list {
    max-height: 160px !important;
  }
}
