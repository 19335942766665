.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 500px;
  overflow: auto;

  &-title {
    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: var(--primary-text-color);
      margin-bottom: 16px;
      display: block;
    }
  }

  &-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: var(--secondary-text-color);
  }

  &-controls {
    // padding-top: 20px;
    // padding: 0 24px 24px 24px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .text-wrapper {
    overflow-y: auto;
    max-height: 50vh;
  }
}

.full {
  min-height: 300px;
  overflow: hidden;
}

.controls__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  button {
    margin: 0 0 0 15px;
  }
  .hidden {
    visibility: hidden;
  }
}

.wallet {
  display: flex;
  width: 100%;

  &__desc {
    span:last-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100px;
    }
  }
  label {
    width: 100%;
  }
}
.wallet-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    // margin-right: 15px;
    width: 100%;

    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
}

.wallet__item {
  display: flex;
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 15px;
  align-items: center;
  cursor: pointer;
  &.selected {
    border: 1px solid var(--main-color);
    background: var(--main-light-color);
  }
  &.error {
    border: 1px solid var(--red);
  }
}
.wallet__icon i {
  display: block;
  width: 52px;
  height: 52px;
  margin-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.wallet__desc {
  span {
    display: block;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;

    font-size: 11px;
    line-height: 18px;
  }

  span:nth-child(2) {
    word-break: break-all;
  }

  span:first-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}

.btc {
  background-image: url('/assets/img/crypto/btc.svg');
}
.ltc {
  background-image: url('/assets/img/crypto/ltc.svg');
}
.eth {
  background-image: url('/assets/img/crypto/eth.svg');
}
.dash {
  background-image: url('/assets/img/crypto/dash.svg');
}
.doge {
  background-image: url('/assets/img/crypto/doge.svg');
}
.bcy {
  background-image: url('/assets/img/crypto/bcy.svg');
}
.usdt {
  background-image: url('/assets/img/crypto/tether.svg');
}
.usdc {
  background-image: url('/assets/img/crypto/usdc.svg');
}
.trx {
  background-image: url('/assets/img/crypto/trx.svg');
}

.transaction {
  &__info {
    display: flex;
    @media (max-width: 450px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__desc {
    border-radius: 4px;
    padding: 8px;
    background: rgba(242, 153, 74, 0.12);
    margin-left: 30px;
    @media (max-width: 450px) {
      margin-left: 0px;
      margin-top: 15px;
    }
  }

  &__desc {
    span {
      display: block;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;

      text-align: center;
    }

    span:nth-child(2) {
      font-weight: 600;
    }
  }

  &__empty {
    display: block;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  &__loadMoreBtn {
    display: flex;
    justify-content: center;
  }
}

.copy_id {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
