.new-account__page {
  position: relative;
  top: -52px;
  button {
    margin: 0 0 0px auto;
  }

  @media (max-width: 450px) {
    top: -9px;
  }
}
