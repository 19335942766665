.dashboard {
  display: flex;
  min-height: 100vh;
  background-color: var(--body-background-color);
  &.mobile {
    height: auto;

    .dashboard__content {
      padding: 130px 0 0 0 !important;
    }
  }

  .dashboard__sidebar {
    //width: 270px;
    background: var(--sidebar-background-color);
    box-shadow: 1px 0 0 var(--outline-text-color);
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100%;
    transition: width 0.3s ease-in-out;

    &:hover {
      .side-navigation-panel-select-inner-option {
        left: 0 !important;
      }

      .side-navigation-panel-select-inner-option-wrap {
        right: 0 !important;
      }
    }
  }
  .dashboard__content {
    width: 100%;
    padding: 70px 0 0 270px;
    transition: all 0.3s ease-in-out;
    //width: 100%;
    //padding: 90px 0 0 270px;
    //position: relative;
    //display: flex;
    //flex-direction: column;
  }
  .dashboard__content-wrap {
    // padding: 20px;
  }
  .copyright {
    // position: absolute;

    // left: 50%;
    padding-bottom: 10px;
    color: var(--disabled-text-color);
    font-size: 14px;
    //padding: 16px;
    width: 100%;
    margin-top: auto;
    text-align: center;
  }
}

