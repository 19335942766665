.finance__page {
  .panel {
    &__title {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 23px;
      margin-bottom: 16px;
      margin-left: 32px;
    }
  }
  .finance__page-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    &:not(:last-child) {
      margin-bottom: 32px;

      @media (min-width: 700px) and (max-width: 1200px) {
        flex-wrap: wrap;
        .finance__page-col {
          width: 43%;
        }
      }
    }

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
  .finance__page-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    &:not(:last-of-type) {
      margin-right: 31px;
    }

    @media (min-width: 700px) and (max-width: 1200px) {
      margin-right: 0px;
      margin-bottom: 31px;
      justify-content: space-between;
    }

    @media (max-width: 700px) {
      margin-right: 0px;
      margin-bottom: 31px;

      &:not(:last-of-type) {
        margin-right: 0px;
      }
    }
  }
  .link {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #722ed1;
  }
  .trigger-btn {
    margin: 0;
  }
}
.infoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 130px);
  p {
    margin-bottom: 20px;
  }
}
