.forgot__password {
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-background-color);
    background-size: cover;
    height: 100vh;

    &__box {
      background-color: var(--white);
      min-width: 700px;
      max-width: 767px;
      min-height: 300px;
      position: relative;
      border-radius: 10px;
      box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.73);
      padding: 20px 20px;
      @media (max-width: 768px) {
        min-width: 100%;
      }
    }

    &__box-header {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__box-header-icon {
      color: var(--green);
      margin-bottom: 20px;
    }

    &__box-lang-switcher {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &__box-content {
      text-align: center;
      font-size: 16px;
    }

    &__box-footer {
      text-align: center;
      font-size: 16px;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }
}

.forgot__password {
  .logo {
    position: absolute;
    height: 54px;
    top: -90px;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);

    svg, img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .login {
    background-color: var(--white);
    min-width: 700px;
    max-width: 767px;
    min-height: 200px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.73);
    display: flex;
    @media (max-width: 768px) {
      min-width: 100%;
    }

    &__col {
      &:nth-child(1) {
        border-radius: 10px 0 0 10px;
        width: 35%;
        max-width: 250px;
        background-color: var(--light-grey-disabled);
        color: var(--light-grey);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 10px 20px 20px;
      }

      &:nth-child(2) {
        width: 70%;
        padding: 20px 20px 20px 20px;
        background-color: var(--body-background-color);
        @media (max-width: 580px) {
          width: 100% !important;
        }
      }
    }

    &__title {
      text-transform: uppercase;
      text-align: center;
      font-size: 24px;

      @media (max-width: 600px) {
        font-size: 20px;
      }

      @media (max-width: 350px) {
        font-size: 17px;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      flex-wrap: wrap;
    }

    &__footer {
      padding-top: 20px;
      font-size: 14px;
    }
  }

  .form {
    width: 100%;
    position: relative;

    .label {
      margin-bottom: 5px;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    &__input-wrap {
      width: 100%;
      position: relative;
      margin-bottom: 15px;

      input {
        width: 100%;
        padding: 10px;
        border: 1px solid var(--black);
        border-radius: 4px;
        outline: none;
      }

      input.error {
        border-color: var(--red);
      }
    }

    .button-wrap {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      line-height: normal;

      .text-link {
        margin-right: auto;
      }
    }
  }

  .auth {
    &__descr {
      @media (max-width: 580px) {
        display: none !important;
      }
    }

    &__descr-img {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;

      & img {
        max-width: 150px;
      }
    }

    &__descr-text {
      max-width: 100%;

      p {
        font-size: 14px;
        color: var(--secondary-text-color);
        margin-bottom: 5px;
      }

      .link-text {
        color: var(--main-color);
        font-size: 14px;
        text-decoration: underline;

        &:hover {
          color: var(--main-color-hover);
          cursor: pointer;
        }
      }
    }
  }
}
