.date-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span:last-of-type {
    color: #595959;
    font-size: 12px;
  }
}
.status,
.type,
.deposit,
.withdraw,
.transfer_in,
.transfer_out,
.credit_in,
.credit_out {
  display: block;
}
.transfer_in {
  color: var(--main-color);
}
.transfer_out {
  color: var(--yellow);
}
.credit_in {
  color: var(--light-red-border);
}
.credit-out {
  color: var(--blue);
}
.deposit,
.completed {
  color: #53c41a;
}
.withdraw,
.canceled {
  color: #f6222e;
}
.pending {
  color: #2f80ed;
}
.transaction__id {
  display: flex;
  flex-direction: row;
  .live,
  .archived {
    display: block;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    margin-left: 10px;
  }
  .live {
    background: #53c41a;
  }
  .archived {
    background: #f6222e;
  }
}
.trading_account,
.transaction__id {
  // font-size: 13px;
}
.trading_account {
  position: relative;
  span {
    position: relative;
    top: -10px;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    background: #fbad15;
    margin-left: 5px;
    color: #ffffff;
  }
  p {
    display: inline-block;
  }
}
.status:first-letter,
.type:first-letter,
.deposit:first-letter,
.withdraw:first-letter,
.transfer_in:first-letter,
.transfer_out:first-letter {
  text-transform: uppercase !important;
}
