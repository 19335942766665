.control-group {
  border: 1px solid var(--border-background-color);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  height: 100%;
  width: 100%;
  .control-group-row {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    &:last-child {
      margin: auto 0 0;
    }
  }
}
