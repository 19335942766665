.lk-select-component {
  .title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    display: block;
    width: 100%;
    color: var(--primary-text-color);
  }

  &__control {
    cursor: pointer !important;
  }
}

.lk-option {
  cursor: pointer !important;

  &__title,
  &__subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    line-height: 20px;
    align-items: center;
    display: block;
  }

  &__title {
    font-weight: 700;
    font-size: 13px;
    color: #595959;
    @media (max-width: 800px) {
      font-size: 11px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    color: #262626;
    @media (max-width: 800px) {
      font-size: 11px;
    }
  }

  .account__status {
    padding: 2px 8px;
    border-radius: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-left: 5px;

    color: #ffffff;
    // &.active {
    //     background: #53C41A;
    // }

    &.pending,
    &.active {
      background: #fbad15;
    }
  }
}


