.chat-dialog-page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  width: 950px;
  height: 100%;
  padding-top: 70px;
  margin: 0 auto;
  background-color: var(--light-grey-secondary);
  border-radius: 5px;

  .dialog-header {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 22px 15px;
    border-bottom: 1px solid var(--main-color);
    background-color: var(--light-grey-secondary);
    font-size: 24px;
    font-weight: 500;
  }

  .context-menu {
    width: 165px;
    height: 160px;
  }

  .context-menu--active {
    height: auto;
  }
}
