.lang-switcher {
  display: flex;
  align-items: center;
  select {
    border: none;
  }
  img {
    height: 25px;
    width: 25px;
  }
}
.single-value {
  &__container {
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  &__icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  &__text {
    margin-right: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    line-height: normal;
    font-size: 14px;
  }
}

.option {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  &:last-child {
    padding-bottom: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: var(--main-light--color);
  }
  &__icon {
    margin-right: 5px;
  }
}
