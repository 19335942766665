.verify-email__page {
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-background-color);
    background-size: cover;
    height: 100vh;
    &__box {
      background-color: var(--white);
      min-width: 700px;
      max-width: 767px;
      min-height: 300px;
      position: relative;
      border-radius: 10px;
      box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.73);
      padding: 20px 20px;
      @media (max-width: 768px) {
        min-width: 100%;
      }
    }
    &__box-header {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__box-header-icon {
      color: var(--green);
      margin-bottom: 20px;
    }
    &__box-lang-switcher {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    &__box-content {
      text-align: center;
      font-size: 16px;
    }
    &__box-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      button {
        margin-left: 0;
      }
    }
    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

}
