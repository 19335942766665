.header {
  width: 100%;
  padding: 15px 18px 15px 0;
  box-shadow: 0 1px 0 var(--light-grey);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  background: var(--header-background-color);

  &.mobile {
    padding: 0 10px 0 10px;
    background-color: var(--main-color);

    @media (min-width: 320px) and (max-width: 600px) {
      .header__col:nth-of-type(1) {
        padding-right: 0;
        padding-left: 10px;
        order: 1;
      }
      .header__col:nth-of-type(2) {
        order: 3;
        width: calc(100% + 20px);
        background-color: var(--white);
        margin-left: -10px !important;
        margin-right: -10px;
        flex-direction: row;
        justify-content: center !important;
        padding: 10px;
      }

      .header__col:nth-of-type(3) {
        padding: 10px 0;
        order: 2;
        border-left: none !important;
      }
      .balance {
        margin-right: 0;
        padding-left: 10px;
        // display: flex;
        // align-items: center;
        &__title {
          display: block;
          color: var(--primary-text-color);
        }
        &__count {
          flex-direction: column;
          div {
            width: 100%;
            color: var(--main-color);
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
    .header__col:nth-of-type(1) {
      padding-right: 10px;
      padding-left: 10px;
    }
    .header__col:nth-of-type(2) {
      margin-right: -10px;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      margin-left: auto;
    }

    .header__col:nth-of-type(3) {
      padding: 10px 0;
      border-left: none !important;
    }

    .header__container {
      justify-content: space-between;
      flex-wrap: wrap;
      display: flex;
    }

    .header__col {
      flex-direction: column;
      align-items: stretch;
      border: 1px solid transparent;
      &:last-child {
        padding-left: 20px;
        border-left: 1px solid var(--light-grey);
      }
    }

    .balance {
      margin-right: 0;
      padding-left: 10px;
      &__title {
        color: var(--primary-text-color);
      }
    }

    .balance__count {
      font-size: 13px;
      display: flex;
      color: var(--white);
      div {
        margin-bottom: 5px;
        &:first-child {
          padding-right: 10px;
          margin-right: 10px;
          border-right: 2px solid var(--white);
        }
      }
    }
    .hr {
      width: 1px;
      background-color: var(--light-grey);
      margin-right: 22px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__col {
    display: flex;
    align-items: center;

    padding: 0 20px;
    border-left: 1px solid var(--light-grey);
    &:first-child {
      border-left: none;
    }
  }
}
.balance {
  margin-right: 10px;
  &__title {
    font-size: 14px;
    text-transform: uppercase;
    color: var(--primary-text-color);
    margin-bottom: 5px;
    @media (min-width: 800px) and (max-width: 950px) {
      font-size: 12px;
    }
  }
  &__count {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--main-color);
    font-weight: 600;
    display: flex;
    div {
      margin-bottom: 5px;
      margin-right: 10px;
      &:first-child {
        padding-right: 10px;
        border-right: 2px solid var(--main-color);
      }
    }
    @media (min-width: 800px) and (max-width: 950px) {
      font-size: 12px;
    }
  }
}
