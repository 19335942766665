.meta-trader__page {
  .download__img {
    max-width: 200px;
    max-height: 70px;
  }

  & .meta-trader__page-webtrader {
    & a {
      padding: 10px 20px;
      border-radius: 36px;
      background-color: var(--main-color);
      color: var(--white);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      outline: none;
      border-width: 1px;
      border-color: var(--main-color);
      line-height: 18px;
      text-decoration: none;
      transition: 0.1s ease-in all;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:hover {
        background-color: var(--main-color-hover);
      }
    }
  }
}
