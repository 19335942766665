.password-button {
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  width: 30px;
  height: 50px;

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url('/assets/img/icon/change-password-icon.svg');

    &:hover {
      opacity: 80%;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    polyline,
    path,
    rect {
      stroke: var(--main-color);
    }
    rect {
      fill: var(--main-color);
    }
    rect:first-of-type {
      display: none;
    }
  }

  &:hover,
  &:active {
    path {
      fill: var(--main-color-hover);
    }
  }
}
