.checkbox__icon {
  .input-wrap {
    font-size: 13px;
    line-height: normal;
    display: flex;
    align-items: flex-start;
  }

  .label-wrap {
    align-items: center;
    display: flex;
    position: relative;
    justify-content: space-between;

    .input__label,
    .input__label-no-click {
      font-size: 13px;
      color: var(--primary-text-color);
      display: block;
      user-select: none;
      height: 100%;
      width: 100%;
      position: relative;

      &.error {
        color: var(--red);
        &:before {
          border-color: var(--red);
        }
      }

      &:first-letter {
        text-transform: uppercase;
      }
    }
    .input__label-no-click {
      padding: 0;
      max-width: 94%;
    }

    &.no-click {
      align-items: flex-start;
    }
  }

  .input {
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;

    background: var(--main-color);

    &:focus {
      border-color: var(--main-color);
    }
    &:active {
      border-color: var(--primary-text-color);
    }
  }

  .label-wrap .input__label::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #595959;
    border-radius: 4px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
  }
  .icon-checked {
    background-color: var(--main-color);
    position: relative;
    &:before{
      content: '';
      width: 15px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('/assets/img/icon/check.svg') !important;
    }

  }
  .input:checked + .label-wrap .input__label::before {
    //display: none;

  }

  .checkbox-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #595959;
    border-radius: 4px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;

    &.error {
      border-color: var(--red);
    }
  }

  .checked-icon {
    background-image: url('/assets/img/icon/checkbox_checked.svg');
  }
  .required {
    color: var(--red);
    margin-left: 3px;
  }
  .errorName {
    position: absolute;
    bottom: -15px;
    left: 0;
    font-size: 13px;
    color: var(--red);
  }
}

