.context-menu {
  z-index: -1;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  background: var(--white);
  border-radius: 4px;
  padding: 4px 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.34);

  &--active {
    z-index: 5;
    pointer-events: all;
    opacity: 1;
    transition: opacity .1s ease;
  }

  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: unset;
    border: unset;
    margin: 0;
    border-radius: 0;
    padding: 8px 16px;

    &:hover {
      background-color: var(--list-hover-option-color) !important;
    }
  }
}