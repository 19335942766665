.loader-wrap {
  width: 100%;
  height: 100%;
  z-index: var(--zIndex-super-main);
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.loader {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);

  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 7px solid var(--outline-text-color);
  border-top: 7px solid var(--main-color);
  animation: spin 2s linear infinite;

  &__neutral {
    border-top-color: var(--primary-text-color);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
