.trading-table {
  margin-top: 30px;
  table {
    thead tr {
      th {
        width: 8% !important;
      }
    }
  }

  .buy {
    text-transform: uppercase;
    color: var(--green);
    font-weight: 700;
  }
  .sell {
    text-transform: uppercase;
    color: var(--red);
    font-weight: 700;
  }
  .date-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span:last-of-type {
      color: #595959;
      font-size: 12px;
    }
  }
}

