.transaction-history-table {
  table {
    width: 100%;
    thead tr {
      th:nth-child(1) {
        width: 80px !important;
      }
      th:nth-child(2) {
        width: 140px !important;
      }
      th:nth-child(3) {
        width: 120px !important;
      }
      th:nth-child(4) {
        width: 120px !important;
      }
      th:nth-child(5) {
        width: 120px !important;
      }
      th:nth-child(6) {
        width: 100px !important;
      }
      th:nth-child(7) {
        width: 100px !important;
      }
      th:nth-child(8) {
        width: 100px !important;
      }
      th:nth-child(9) {
        width: 100px !important;
      }
      th:nth-child(10) {
        width: 100px !important;
      }
      th:nth-child(11) {
        width: 120px !important;
      }
      th:nth-child(12) {
        width: 120px !important;
      }
      th:nth-child(13) {
        width: 100px !important;
      }
      th:nth-child(14) {
        width: 100px !important;
      }
      th:nth-child(15) {
        width: 120px !important;
      }
      th:nth-child(16) {
        width: 120px !important;
      }
    }
  }

  .buy {
    text-transform: uppercase;
    color: var(--green);
    font-weight: 700;
  }
  .sell {
    text-transform: uppercase;
    color: var(--red);
    font-weight: 700;
  }
  .date-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span:last-of-type {
      color: #595959;
      font-size: 12px;
    }
  }

  .plPos {
    color: var(--green);
  }

  .plNeg {
    color: var(--red);
  }
}
