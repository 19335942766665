.change-password__page {
  .sub-title {
    font-size: 20px;
    line-height: 28px;
    color: var(--primary-text-color);
    margin-bottom: 16px;
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    .btn {
      margin-left: 20px;
    }
  }
}


