.opened-position__page {
  .filters {
    .title {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #262626;
      margin-bottom: 5px;
      display: block;
      width: 100%;
      color: var(--primary-text-color);
    }
    .container {
      padding: 0;
    }

    &-form {
      @media (max-width: 800px) {
        > div > div > div {
          padding: 0;
          margin-bottom: 10px;
        }
      }

      // @media (min-width: 800px) and (max-width: 1200px) {
      //   overflow-x: scroll;
      //   > div {
      //     width: 1200px;
      //     max-width: 1200px;
      //     padding-bottom: 15px;
      //   }
      // }
    }

    .radio-buttons {
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #c2c2c2;
      margin-bottom: 15px;

      @media (max-width: 768px) {
        > div > div {
          // width: auto;
        }
      }

      input[type='radio']:checked + label,
      input[type='radio']:active + label {
        background-color: var(--main-color) !important;
        border-color: var(--main-color) !important;
      }

      label {
        width: 100%;
        background: transparent;
        border-color: transparent;
        color: var(--bs-body-color);
      }
    }
    &-form {
      .row {
        --bs-gutter-x: 0 !important;
        margin: 0;
      }

      &__btns {
        display: flex;
        justify-content: flex-end;

        button {
          margin-right: 0;
        }

        @media (min-width: 800px) and (max-width: 1200px) {
          // justify-content: flex-start;
          padding: 0;
          button {
            margin-left: 0;
            margin-right: 15px;
          }
        }
      }
    }
  }

}
