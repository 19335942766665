.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px !important;
  margin-left: auto;

  @media (max-width: 500px) {
    > span,
    select {
      font-size: 14px !important;
    }
  }
  @media (max-width: 800px) {
    justify-content: flex-start;
    flex-wrap: wrap;

    > span {
      font-size: 18px;
    }

    > select {
      font-size: 18px;
    }
  }
  button {
    border: 0;
    outline: none;
    background: transparent;
    color: var(--main-color);
    margin-right: 10px;

    @media (max-width: 800px) {
      font-size: 30px;
    }

    @media (max-width: 325px) {
      margin-right: 9px;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
  span {
    color: var(--main-color);
  }
  input {
    margin: 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #262626;
    border-radius: 3px;
    max-width: 60px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.3);
      outline: none;
    }
  }
  .goToSelectedPage-input {
    background-color: var(--body-background-color);
  }
  select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: transparent;
    border-radius: 3px;
    padding: 0 4px;
    color: #262626;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.3);
      outline: none;
    }
  }
  @media (max-width: 585px) {
    margin-left: 0;
    .goToSelectedPage {
      margin-left: auto;
    }
    .goToFirstPage,
    .goToLastPage,
    .pageText {
      display: none;
    }
    input {
      max-width: 40px;
    }
    button {
      padding: 0;
    }
    select {
      padding: 0 4px;
    }
  }
}
