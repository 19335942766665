.date-picker__range {
  .date-range {
    position: relative;
    .date-input {
      position: relative;
      label,
      input {
        line-height: 20px;
      }
      input {
        padding: 13px 30px;
      }

      .icon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: var(--secondary-text-color);
      }
    }

    .date-range {
      position: absolute;
      top: 82px;
      z-index: 10;
      left: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
  }
  //.rdrDayToday {
  //  background-color: var(--main-color) !important;
  //}
  .rdrDateDisplayItem {
    border-color: var(--main-color) !important;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: var(--main-color);
  }

}
